




































import {Component, Prop, Vue} from "vue-property-decorator";
import {IWorkStruct} from "@store/works/Interface";

@Component({
  name: "WorkStructCard"
})
export default class WorkStructCard extends Vue {
  @Prop(Object) readonly workStruct!: IWorkStruct

  private openWorkStruct(workStruct: IWorkStruct) {
    this.$emit('changeActiveWorkStruct', workStruct)
    this.$emit('expandActiveWorkStruct', workStruct.id)
  }

}

